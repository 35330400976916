import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "github-actions"
    }}>{`Github Actions`}</h1>
    <p>{`A collection of Github Actions for automate common workflows:`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/github-actions/lerna-release" mdxType="Link">Lerna Release</Link>  
        <div><small>A Github Action for release monorepo packages under registry.wild.plus.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/github-actions/semantic-release" mdxType="Link">Semantic Release</Link>  
        <div><small>A Github Action for release a package under registry.wild.plus.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/github-actions/pr-changelog-generator" mdxType="Link">Pull Request Changelog Generator</Link>  
        <div><small>A Github Action for generate a changelog, based on the merged pull request labels.</small></div>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      